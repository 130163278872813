import { FC, useContext, useState } from "react";
import { useRouter } from "next/router";
import { BurgerIcon } from "@dustlabs/web/icons";
import {
  ClientOnly,
  ConnectButton,
  LogoIcon,
  MobileMenu,
} from "@shared/components";
import { useDeId } from "@dustlabs/profiles/core";
import { GLOBAL_CONFIG } from "@shared/constants";
import { AppConfig, ProjectConfig } from "@shared/types";
import { useAccount } from "wagmi";

const AppBarMobile: FC = () => {
  const { isConnected } = useAccount();
  const { pathname } = useRouter();
  const { isSignedIn } = useDeId();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const { APPS } = GLOBAL_CONFIG as ProjectConfig;
  const config = APPS.find((appItem) => appItem.path === pathname) as AppConfig;

  return (
    <nav className="h-[73px] lg:hidden w-full px-5 sm:px-10 py-3 flex justify-between bg-darkBackground z-50">
      <div className="w-8 flex items-center">
        <LogoIcon />
      </div>

      <div className="flex items-center gap-3">
        <ClientOnly>
          {!config?.disableWallet && (
            <div
              className={`z-20 flex items-center ${
                (isConnected ||
                  pathname.includes("rewards") ||
                  pathname.includes("clubs") ||
                  pathname.includes("explorer") ||
                  pathname.includes("migration/status")) &&
                pathname !== "/" &&
                ((isSignedIn && pathname === "/holdings") ||
                  pathname !== "/holdings")
                  ? "visible"
                  : "invisible"
              }`}
            >
              <ConnectButton />
            </div>
          )}
        </ClientOnly>

        <div
          onClick={() => setShowMenu(true)}
          className="w-10 p-1 flex items-center hover:cursor-pointer"
        >
          <BurgerIcon height={44} width={44} className="fill-darkFontPrimary" />
        </div>
      </div>

      <MobileMenu isOpen={showMenu} handleClose={() => setShowMenu(false)} />
    </nav>
  );
};
export default AppBarMobile;
